import React from 'react'
import Layout from '../layout/basic.js'

const NotFoundPage = () => (
  <Layout>
    <section className="contact_us">

      <article className="hero header-hero has-text-centered">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">NOT&nbsp;FOUND</h1>
            <h2 className="subtitle">404</h2>
          </div>
        </div>
      </article>

      <article className="main-contents has-text-centered">
        <div className="container columns is-multiline">
          <div className="column is-12">
            <div className="columns">
              <div className="column is-4 is-offset-4">
                <p>ご指定頂いたページが見つかりませんでした。
                	リンクよりページを選択してください。</p>
              </div>
            </div>
          </div>
        </div>
      </article>

    </section>
  </Layout>
);
export default NotFoundPage;
